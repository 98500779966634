<template>
  <div>
    <h2 class="headline--md text-bold text-uppercase">Withdraw gold order detail</h2>

    <div class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Order No.</p>
                <h2 class="headline--md mt-4">123456790</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Payment No.</p>
                <h2 class="headline--md mt-4">123456790</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 class="headline--md mt-4">PENDING</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Gold Amount (gram)</p>
                <h2 class="headline--md mt-4">0.02</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Fee (TOKO)</p>
                <h2 class="headline--md mt-4">{{ 30000 | number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Order Time</p>
                <h2 class="headline--md mt-4">9:40 AM, 3/29/2020 UTC</h2>
              </div>
            </td>
          </tr>

          <tr class="has-border">
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Delivery Address</p>
                <h2 class="headline--md mt-4">JIn. Abdul Muis No.46, Jawa Barat, Bandung, 10160</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Proof of Delivery Address</p>
                <img @click="isImageSliderOpen = true" src="/images/gold-bg.jpg" alt="" />
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <div class="text-center">
                <button class="btn btn--red-bordered">Reject</button>
                <button class="btn btn--primary">Approve</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="headline--md text-bold text-uppercase mt-32">Account information</h2>
    <div class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">dinhhaildh9@gmail.com</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">dinhhaildh9</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Phone</p>
                <h2 class="headline--md mt-4">+62 1223456789</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Blacklist</p>
                <h2 class="headline--md mt-4 text-error text-bold">{{ 1 | format_blacklist_type }}</h2>
              </div>
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <modal v-if="isImageSliderOpen" :open.sync="isImageSliderOpen">
      <image-slider ref="imageSlider" :currentIndex="1" :images="['/images/gold-bg.jpg']" />
    </modal>
  </div>
</template>

<script>
const ImageSlider = () => import('@/components/ImageSlider')
const Modal = () => import('@/components/Modal')

export default {
  data() {
    return {
      isImageSliderOpen: false
    }
  },

  components: {
    Modal,
    ImageSlider
  }
}
</script>
